import { init as initKeeper, appReady as coreKeeperAppReady } from '@mail-core/dashboard/keeper';
import { init as initCoreLogger } from '@mail-core/logger/inline/logger';

import Xray from './Xray';
import OAuth from './OAuth';
import MailBox from './MailBox';
import Auth from './Auth';

import {XraySendParams} from '../interfaces/Xray';

const xrayService: Xray = new Xray();
const authService: Auth = new Auth();
const oauthService: OAuth = new OAuth(xrayService);
const mailBoxService: MailBox = new MailBox(oauthService);

/**
 * Основной класс
 *
 * Экземпляр экспортируется в **window.promokit**
 */
export default class Promokit {
	public authService = authService;

	public oauthService = oauthService;

	public mailBoxService = mailBoxService;

	public xrayService = xrayService;

	/** @ignore */
	constructor() {
		const xray = xrayService.xray;

		try {
			initKeeper({xray});
			coreKeeperAppReady();
		} catch (error) {
			coreKeeperAppReady(error);
		}

		try {
			initCoreLogger({
				xray,
				errors: {
					silent: !!window.LOGGER_ERRORS_SILENT,
					logByDefault: !!window.LOGGER_ERRORS_LOG,
				},
			});
		} catch (error) {
			//
		}
	}

	// https://gitlab.corp.mail.ru/mail/mrg-xray
	/**
	 * См. {@link Xray.sendRadar}
	 *
	 * @example
	 * ```js
	 * // Отправка радара
	 * promokit.sendRadar('click', {
	 * 	i: 'button1',
	 * });
	 * ```
	 *
	 * @example
	 * ```js
	 * // Отправка радара
	 * promokit.sendRadar('click_button1');
	 * ```
	 *
	 * @param name Имя метрики
	 * @param params Параметры
	 */
	sendRadar(name: string, params: XraySendParams) {
		return xrayService.sendRadar(name, params);
	}

	/**
	 * См. {@link Auth.getActiveUser}
	 *
	 * @example
	 * ```js
	 * // Получить email и имя пользователя или проверить, что пользователь авторизован
	 * promokit.getActiveUser()
	 *	.then(function (data) { // пользователь авторизован
	 *		console.log('Данные пользователя:', data);
	 *	})
	 *	.catch(function (error) {
	 *		if (error === 'noauth') {
	 *			console.log('Пользователь не авторизован');
	 *		} else {
	 *			console.log('Ошибка:', error);
	 *		}
	 *	});
	 * ```
	 *
	 * @example
	 * ```js
	 * // Если пользователь авторизован - открыть форму написания письма с параметрами, если нет - показать форму логина
	 * var url = promokit.mailBoxService.getComposeUrl({
	 * 	to: 'test@mail.ru',
	 * 	subject: 'Поздравляю с 8 Марта!',
	 * 	body: 'Поздравляю с праздником!\n' +
	 * 		'\n' +
	 * 		'Вместо тысячи слов дарю этот видеоролик: \n' +
	 * 		'http://8march.mail.ru/ext/y7tg7795\n' +
	 * 		'\n' +
	 * 		'Счастья и любви!',
	 * });
	 *
	 * if (location.search.match(/[?&]authid=/)) {
	 * 	location.href = url;
	 * } else {
	 * 	promokit.getActiveUser()
	 * 		.then(function (data) { // пользователь авторизован
	 * 			console.log('Данные пользователя:', data);
	 *
	 * 			location.href = url;
	 * 		})
	 * 		.catch(function () {
	 * 			// Если не передан параметр success_redirect, то авторизация вернёт на эту же страницу с гет-параметром authid
	 * 			promokit.authService.showForm();
	 * 		});
	 * }
	 * ```
	 */
	getActiveUser() {
		return authService.getActiveUser();
	}
}
