import {MOBILE_USER_AGENT_REGEXP} from './constants';
import userInfo from './userInfo';

import {AuthMailResponse} from '../interfaces/Auth';

/** @ignore */
export const isMobile = () => MOBILE_USER_AGENT_REGEXP.test(navigator.userAgent);

/**
 * См. {@link Auth.getActiveUser}
 * @ignore
 */
export const getActiveUser = async () => {
	const response = await fetch(
		'https://auth.mail.ru/cgi-bin/auth?mac=1',
		{
			method: 'GET',
			credentials: 'include',
		},
	);

	const data: AuthMailResponse = await response.json();
	if (data.status === 'ok') {

		if (data.data) {
			userInfo.set('email', data.data.email);
		}

		return data.data;
	} else {
		throw data.status;
	}
}

/**
 * Вставить скрипт в страницу
 * @param src Ссылка на скрипт
 * @ignore
 */
export const loadScript = (src: string): Promise<void> => new Promise(resolve => {
	const script = document.createElement('script');

	script.onload = () => {
		resolve();
	};

	script.src = src;

	document.head.appendChild(script);
});
