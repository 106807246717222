import queryString from 'query-string';

import xray, {Config, Utm} from '@mail/xray';

import {getActiveUser} from '../utils/helpers';
import {XraySendParams} from '../interfaces/Xray';

const XRAY_CLICK_ATTR = 'data-xray-click';

const getUtmParams = () => {
	const parsed = queryString.parse(location.search);

	const utm: Utm = {
		source: '',
		medium: '',
		campaign: '',
		term: '',
		content: '',
	};

	for (const key in parsed) {
		if (Object.prototype.hasOwnProperty.call(parsed, key)) {
			const utmKey = key.replace(/^utm_/, '');
			if (utmKey in utm) {
				utm[utmKey] = parsed[key];
			}
		}
	}

	return utm;
}

const getXrayAttr = node => {
	while (node && node.tagName.toLowerCase() !== 'body') {
		if (node.hasAttribute(XRAY_CLICK_ATTR)) {
			return node.getAttribute(XRAY_CLICK_ATTR);
		}
		node = node.parentElement;
	}
}

// https://gitlab.corp.mail.ru/mail/mrg-xray
/**
 * Сервис для работы с радарами
 *
 * - автоматически конфигурируется (email авторизованного пользователя, [UTM-метки](https://support.google.com/analytics/answer/1033863));
 * - вешается обработчик `document.addEventListener('click')`, который на каждый клик по элементу ищет элеиент с атрибутом **data-xray-click**="attr_value" и отсылает радар со значением этого атрибута;
 * - вешается обработчик на `window.onerror`, который отсылает радары об ошибках на странице;
 * - дёргается радар **page_load** при загрузке страницы.
 */
export default class Xray {
	/**
	 * Ссылка на библиотеку
	 */
	public xray = xray;

	/** @ignore */
	constructor() {
		if (window.XRAY_PROJECT) {
			const xrayConfig: Config = {
				verbose: true,
				utm: getUtmParams(),
				defaultParams: {
					p: `pkit-${window.XRAY_PROJECT}`,
				},
			};

			if (window.XRAY_GA_TRACKING_ID) {
				xrayConfig.gaTrackingId = window.XRAY_GA_TRACKING_ID;
			}

			xray.setConfig(xrayConfig);

			getActiveUser()
				.then(({email}) => {
					xray.setConfig({defaultParams: {email}})
				})
				.catch(() => null);

			xray.send('pk-xray-ready');

			this.addClickListener();
			this.addErrorListner();
		} else {
			throw 'window.XRAY_PROJECT required';
		}
	}

	private addClickListener(): void {
		document.addEventListener('click', event => {
			const xrayAttr = getXrayAttr(event.target);
			if (xrayAttr) {
				xray.sendImmediately('click', {
					i: xrayAttr,
				});
			}
		});
	}

	private addErrorListner(): void {
		window.onerror = (
			errorMsg,
			url,
			line,
			column,
			errorObj,
		): void => {
			this.sendToRlog('js_error', {
				errorMsg,
				url,
				line,
				column,
				errorObj,
			});
		};
	}

	// https://gitlab.corp.mail.ru/mail/mrg-xray
	/**
	 * Отправить радар
	 *
	 * @example
	 * ```js
	 * // Отправка радара
	 * promokit.xrayService.sendRadar('click', {
	 * 	i: 'button1',
	 * });
	 * ```
	 *
	 * @example
	 * ```js
	 * // Отправка радара
	 * promokit.xrayService.sendRadar('click_button1');
	 * ```
	 *
	 * @param name Имя метрики
	 * @param params Параметры
	 */
	sendRadar(name: string, params?: XraySendParams) {
		return xray.send(name, params);
	}

	/** @ignore */
	sendToRlog(name, data): void {
		const xrayConfig = xray.getConfig();
		return xray.sendImmediately(name, {
			rlog: `${xrayConfig.defaultParams.p}_${name}`,
			rlog_message: data,
		});
	}
}
