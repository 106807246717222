const data = {};

/** @ignore */
export default {
	set(name, value) {
		data[name] = value;
	},

	get(name) {
		return data[name];
	},
};
