import xray from '@mail/xray';
import {InitOptions} from '@mail/oauth';
import { api as coreKeeperAPI } from '@mail-core/dashboard/keeper';
import { now } from '@mail-core/dashboard/keeper/binding/util.now';

import {getActiveUser, loadScript} from '../utils/helpers';

import {OAUTH_LIB_URL, CORSAPI_ORIGIN} from '../utils/constants';
import {OAuthLoadData} from '../interfaces/OAuth';

const objectToFormData = data => {
	const formData = new FormData();

	for (const key in data) {
		if (Object.prototype.hasOwnProperty.call(data, key)) {
			if (typeof data[key] === 'object') {
				formData.append(key, JSON.stringify(data[key]));
			} else {
				formData.append(key, data[key]);
			}
		}
	}

	return formData;
};

let OAuthEmail: string = null;
let OAuthAccessToken: string = null;

let oauth = null;

/**
 * Сервис для работы с OAuth
 */
export default class OAuth {
	/** @ignore */
	constructor(private xrayService) {
	}

	private getEmail(): Promise<string> {
		if (OAuthEmail) {
			return Promise.resolve(OAuthEmail);
		} else {
			return getActiveUser()
				.then(({email}) => OAuthEmail = email);
		}
	}

	private getAccessToken(): Promise<any> {
		if (OAuthAccessToken) {
			return Promise.resolve(OAuthAccessToken);
		} else {
			const apiComplete = coreKeeperAPIHelper('oauth/auth');

			return this.getOAuth()
				.then(({oauth}) => {
					return new Promise((resolve, reject) => {
						const timeoutId = setTimeout(() => {
							apiComplete(408); // Отвалились по таймауту
							xray.send('oauth', {
								i: 'auth_timeout',
							});

							reject('timeout');
						}, 5000);

						oauth.auth(state => {
							apiComplete(200);
							apiComplete(200, 200, `oauth/auth-${state.status}`); // И отдельно каккой «статус» вернули

							xray.send('oauth', {
								i: 'status_' + state.status
							});

							clearTimeout(timeoutId);

							if (state.status === oauth.AUTH_CONNECTED) {
								resolve(OAuthAccessToken = state.access_token);
							} else {
								reject('noauth');
							}
						});
					})
				});
		}
	}

	private getAuthData() {
		return Promise.all([this.getEmail(), this.getAccessToken()])
			.then(([email, token]) => ({email, token}));
	}

	/**
	 * Загрузить и получить ссылку на библиотеку OAuth
	 *
	 * @example
	 * ```js
	 * // Загрузить и получить ссылку на библиотеку OAuth
	 * promokit.oauthService.getOAuth()
	 * 	.then(function (data) {
	 * 		console.log('Библиотека:', data.oauth);
	 * 	})
	 * 	.catch(function (error) {
	 * 		console.log('Ошибка:', error);
	 * 	});
	 * ```
	 */
	getOAuth(): Promise<OAuthLoadData> {
		if (oauth) {
			return Promise.resolve({oauth});
		} else {
			const url = 'oauth/load';
			const apiComplete = coreKeeperAPIHelper(url);

			if (window.OAUTH_CLIENT_ID) {
				return loadScript(OAUTH_LIB_URL)
					.then(() => {
						if (window.MR) {
							oauth = window.MR;

							const oauthOptions: InitOptions = {
								clientId: window.OAUTH_CLIENT_ID,
							};

							oauth.init(oauthOptions);
							apiComplete(200);

							return {oauth};
						} else {
							apiComplete(503); // Скрипт загружен, но MR-объекта нет

							xray.send('oauth', {
								i: 'load_error',
							});

							throw 'load_error';
						}
					});
			} else {
				apiComplete(406); // Клиент не сконфигурен

				xray.send('oauth', {
					i: 'empty_client_id',
				});

				return Promise.reject('window.OAUTH_CLIENT_ID required');
			}
		}
	}

	/**
	 * Список возможных scope
	 *
	 * mail.api.nosecstep
	 * mail.api.secstep
	 * mail.api.year_stat
	 * mail.api.helpers
	 * mail.api.messages-send-test-amp
	 * mail.api.attaches
	 * mail.api.folders
	 * mail.api.user.short
	 * mail.api.messages.count.all
	 */

	/**
	 * Сделать запрос к серверу
	 *
	 * @example
	 * ```js
	 * // Получить список хелперов
	 * promokit.oauthService.api('helpers')
	 *	.then(function (helpers) {
	 *		console.log('Хелперы:', helpers);
	 *	})
	 *	.catch(function (error) {
	 *		console.log('Ошибка:', error);
	 *	});
	 * ```
	 *
	 * @example
	 * ```js
	 * // Получить данные профиля пользователя
	 * promokit.oauthService.api('golang/user/short')
	 *	.then(function (user) {
	 *		console.log('Данные пользователя:', user);
	 *	})
	 *	.catch(function (error) {
	 *		console.log('Ошибка:', error);
	 *	});
	 * ```
	 *
	 * @param method Метод АПИ
	 * @param params Параметры
	 * @param retryCount Служебный параметр
	 */
	api(method: string, params = {}, retryCount = 1): Promise<any> {
		return this.getAuthData()
			.then(({email, token}) => {
				const url = `${CORSAPI_ORIGIN}/api/v1/${method}`;
				const apiComplete = coreKeeperAPIHelper(url);
				const formData = params instanceof FormData ? params : objectToFormData(params);

				formData.append('email', email);
				formData.append('access_token', token);
				formData.append('htmlencoded', 'false');

				return fetch(url, {
					method: 'POST',
					credentials: 'omit',
					body: formData,
				})
					.then(response => {
						if (!response.ok) {
							// Логируем все не HTTP 2XX
							apiComplete(0, response.status);
						}
						return response.json();
					})
					.then(response => {
						apiComplete(response.status); // HTTP OK, Логируем API STatus

						xray.send('oauth-api', {
							i: method.replace(/\//g, '_') + '_' + response.status,
						});

						if (response.status === 200) {
							return response.body;
						} else if (response.status === 403) {
							if (retryCount-- > 0) {
								OAuthEmail = null;
								OAuthAccessToken = null;

								return this.api(method, params, retryCount);
							} else {
								apiComplete(403, 200, `${url}-retry`); // Кончились повторы
								this.xrayService.sendToRlog('oauth-api-error', response);

								throw response;
							}
						} else {
							this.xrayService.sendToRlog('oauth-api-error', response);

							throw response;
						}
					});
			});
	}
}

function coreKeeperAPIHelper(defUrl: string) {
	const startTime = now();

	return (apiStatus: number, httpStatus = 200, url = defUrl) => {
		coreKeeperAPI(httpStatus, {
			url,
			apiStatus,
			duration: now() - startTime,
		});
	};
}
