// https://github.com/taylorhakes/promise-polyfill#readme
import 'promise-polyfill/src/polyfill';

// https://github.com/github/fetch#readme
import 'whatwg-fetch';

import './utils/errorToJSON';

import Promokit from './classes/Promokit';

export default new Promokit();
