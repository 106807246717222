if (!('toJSON' in Error.prototype)) {
	Object.defineProperty(Error.prototype, 'toJSON', {
		value: function () {
			const alt = {};

			Object.getOwnPropertyNames(this).forEach(key => {
				alt[key] = this[key];
			});

			return alt;
		},
		configurable: true,
		writable: true
	});
}
